<template>
	<v-container
		v-if="view"
	>
	
		<div class="logo-position">
			<v-img
				src="@/assets/img/logo.png"
				width="93"
				style="margin: 0 auto;"
			></v-img>
		</div>

		<v-text-field
			v-model="USER_ID"
			label="아이디"
			outlined
			required
			hide-details
			color="success2"
			
			type="email"
			ref="id"
		></v-text-field>
		
		<div class="mt-5">
		
			<v-text-field
				v-model="USER_PASSWORD"
				label="비밀번호"
				required
				outlined
				class="mt-2"
				color="success2"
				hide-details
				
				:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
				:type="show1 ? 'text' : 'password'"
				@click:append="show1 = !show1"
				
				ref="password"
			></v-text-field>
		</div>
		
		<div
			v-if="account_accept"
			class="mt-2"
			style="color: red; font-size: 0.8em; text-align: right;"
		>
			{{ account_accept }}
		</div>
		
		<div>
			<v-checkbox
				v-model="id_save"
				label="아이디 저장"
				color="success2"
				hide-details
				class="d-inline-block"
			></v-checkbox>
			
			<v-checkbox
				v-if="false"
				v-model="auto_login"
				label="자동 로그인"
				color="success2"
				hide-details
				class="d-inline-block"
			></v-checkbox>
		</div>
		
		<v-btn 
			class="mt-10 success2"
			:disabled="buttons.login"
			@click="login"
			block
		>
			로그인
		</v-btn>
		
		<div class="text-center mt-3 text-caption">
			<router-link 
				:to="'/Help/FindId'" 
				class="text-decoration-none router-link"
			>아이디 찾기
			</router-link>
			|
			<router-link 
				:to="'/Help/FindPw'" 
				class="text-decoration-none router-link"
			>비밀번호 찾기
			</router-link>
			|
			<router-link 
				:to="'/Help/Agree'" 
				class="text-decoration-none router-link"
			>회원가입
			</router-link>
		</div>
	</v-container>
	
</template>

<script>

	import { Base64 } from 'js-base64'
		
	export default {
		created: function(){
			
			if(!localStorage.getItem('paylinkt')){
//				this.$router.push('/home')
			}
			
			this.$emit('setProgram', this.program)
			
			if(localStorage.getItem('paylinks')){
				this.id_save = true
				this.USER_ID = Base64.decode(localStorage.getItem('paylinks'))
			}
			
			this.onAppEvent()
		}
		,components: { }
		,props: ['user', 'callBack']
		,data: () => ({
			program: {
				title: '로그인'
				,not_title: true
				,not_navigation: true
			}
			,view: false
			,valid: false
			,USER_ID: ''
			,USER_PASSWORD: ''
			,email: ''
			,emailRules: [
				v => !!v || 'E-mail is required',
				v => /.+@.+/.test(v) || 'E-mail must be valid',
			]
			,id_save: true
			,auto_login: false
			,show1: false
			,buttons: {
				login: true
			}
			,account_accept: false
		}),
		methods: {
			login: function(){
				//this.$refs.password.focus()
				this.$emit('axios', {
					request_type: 'post'
					,request_url: '/auth/login'
					,request_params: {
						userId : this.USER_ID
						,password : this.USER_PASSWORD
					}
					,callBack: 'setToken'
				})
			}
			,setToken: function(call){

				localStorage.removeItem('id_save')
				localStorage.removeItem('userId')
				localStorage.removeItem('userPW')
				localStorage.removeItem('TOKEN')

				if(call.result){
					localStorage.setItem('paylinkt', call.items.content.access_token)
					localStorage.setItem('paylinkr', call.items.content.refresh_token)
					if(this.id_save){
						localStorage.setItem('paylinks', Base64.encode(this.USER_ID))
					}

					localStorage.setItem('paylinkn', Base64.encode(Base64.encode(this.USER_ID)))
					localStorage.setItem('paylinkp', Base64.encode(Base64.encode(this.USER_PASSWORD)))

					//this.$emit('refreshToken')

					this.$router.push('Home')
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
			,onAppEvent: function(){
        this.view = true
					
				const NUA = navigator.userAgent.toLowerCase()
				console.log(NUA)
				
				if(NUA.indexOf('android') > -1){
					
					try{
						const pin_use = window.Android.getPinCodeUse()
						
						if(pin_use == 'Y'){
							this.$router.push('/Pin')
						}else{
							this.view = true
						}
					}catch(e){
						this.view = true
						console.log(e)
						this.$emit('alertMsg', 'error', 'Pin 로그인은 현재 사용할 수 없습니다.')
					} 
				}else if(
					NUA.indexOf('iphone') > -1
					|| NUA.indexOf('ipad') > -1
					|| NUA.indexOf('ipod') > -1
					){

					try{
						let self = this
						window.webkit.messageHandlers.callback.postMessage({method: 'getPinCodeUse'});
					
						window.addEventListener('message', function(e) {

							if(e.data.method == 'pinCodeUseReturn'){
								if(e.data.params == 'Y'){
									self.$router.push('/Pin')
								}
							}else{
								self.view = true
							}
						});
						
					}catch(error){
						this.view = true
						console.log(error)
						this.$emit('alertMsg', 'error', 'Pin 로그인은 현재 사용할 수 없습니다.1')
					} 
				}
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
				
					console.log('watch callBack ')
					console.log(call)
					
					if(call.name == 'setToken'){
						if(call.result){
							this.setToken(call)
							this.account_accept = ''
						}else{
							this.account_accept = '아이디 또는 비밀번호가 일치하지 않습니다'
						}
					}else if(call.name == 'result'){
						if(call.result){
							this.result(call)
						}else{
							this.$emit('alertMsg', 'error', call.items.message)
						}
					}
				}
			}
			,USER_ID: {
				immediate: true
				,handler: function(){
					if(this.USER_ID && this.USER_PASSWORD){
						this.buttons.login = false
					}else{
						this.buttons.login = true
					}
				}
			}
			,USER_PASSWORD: {
				immediate: true
				,handler: function(){
					if(this.USER_ID && this.USER_PASSWORD){
						this.buttons.login = false
					}else{
						this.buttons.login = true
					}
				}
			}
		}
	}
</script>

<style>
	.logo-position { padding: 50px 0;}
	.v-input--selection-controls {margin-top: 0px;}
	.router-link {color: gray !important; }
	.v-input--selection-controls__input {margin-right: 0px !important;}
</style>
